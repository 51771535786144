import React, {useEffect} from "react";
import {Button, Form, Input, Card, Steps} from "antd";
import {Link, useHistory} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userResendEmail
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";

const FormItem = Form.Item;
const FormStep = Steps.Step;
const EmailVerification = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, showMessage} = useSelector(({auth}) => auth);
  const queryString = require('query-string');
  const parsed = queryString.parse(props.location.search);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }

    if(parsed.status == 'error'){
      message.error('This link has been expired.')
    }
  },[showMessage, parsed.status]);

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(userResendEmail(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <Card className="gx-card" title="Registration">
          <Steps current={1}>
            <FormStep title="Registration" description="Registration"/>
            <FormStep title="Email Verify" description="Email Verify"/>
            <FormStep title="Select PMS" description="Select PMS"/>
            <FormStep title="PMS Detail" description="Enter detail"/>
          </Steps>
        </Card>
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.emailVerification"/></h1>
              <p><IntlMessages id="app.userAuth.bySendMail"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png"/>
            </div>
          </div>

          <div className="gx-app-login-content">
            <p>
              <IntlMessages id="app.userAuth.emailVerificationMessage"/>
               
            </p>
            <p><span><IntlMessages id="app.userAuth.notGetEmail"/></span></p>
            <Form
              initialValues={{remember: true, validate_string : parsed.string}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <FormItem name="validate_string"
                        rules={[{required: true}]}>
                <Input type="hidden" placeholder="validate string"/>
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.resend"/>
                </Button>
              </FormItem>
            </Form>
          </div>
          {loader &&
          <div className="gx-loader-view">
            <CircularProgress/>
          </div>
          }
          {showMessage &&
          message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};


export default EmailVerification;
