import React, {useEffect} from "react";
import {Button, Form, Input, Card, Steps} from "antd";
import {Link, useHistory} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  updateUserPMS
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";

const FormItem = Form.Item;
const FormStep = Steps.Step;
const PmsDetail = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);
  const queryString = require('query-string');
  const parsed = queryString.parse(props.location.search);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
      history.push('/signin');
    }
  },[showMessage]);

  useEffect(() => {
    /** Check user loggedin or not  */
    let token = localStorage.getItem('token');
    if (token && authUser) {
      history.push('/main/dashboard/listing');
    }
  },[authUser]);

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(updateUserPMS(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <Card className="gx-card" title="Registration Progress">
          <Steps current={3}>
            <FormStep title="Registration" description="Registration"/>
            <FormStep title="Email Verify" description="Email Verify"/>
            <FormStep title="Select PMS" description="Select PMS"/>
            <FormStep title="PMS Detail" description="Enter detail"/>
          </Steps>
        </Card>
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.pmsDetail"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png"/>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{remember: true, string : parsed.string, type : 'pms_detail'}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <FormItem name="api_key" rules={[{ required: true, message: 'Please input your API key!'}]}>
                <Input placeholder="API Key"/>
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.submit"/>
                </Button>
              </FormItem>
              <FormItem name="string" rules={[{required: false}]}>
                <Input type="hidden" placeholder="string"/>
              </FormItem>
              <FormItem name="type" rules={[{required: false}]}>
                <Input type="hidden" placeholder="type"/>
              </FormItem>
            </Form>
          </div>
          {loader &&
          <div className="gx-loader-view">
            <CircularProgress/>
          </div>
          }
          {showMessage &&
          message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};


export default PmsDetail;
