import React, {useEffect} from "react";
import {Button, Form, Input, Card, Steps} from "antd";
import {Link, useHistory} from "react-router-dom";
import { GoogleLogin } from 'react-google-login';
import {useDispatch, useSelector} from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userSignUp,
  userGoogleSignIn
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";

const FormItem = Form.Item;
const FormStep = Steps.Step;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, showMessage, authUser, errors} = useSelector(({auth}) => auth);
  const formRef = React.useRef(null);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  });

  /*** Show errors */
  useEffect(() => {
    if(errors && Object.keys(errors).length > 0){
      formRef.current.setFields([errors]);
    }
    
    let token = localStorage.getItem('token');
    if (!token && authUser !== null) {
      history.push('/email_verification?string='+authUser);
    }

    /** Check user loggedin or not  */
    if (token && authUser) {
      history.push('/main/dashboard/listing');
    }
  },[errors, authUser]);

  const onFinishFailed = errorInfo => {
  };

  const responseGoogle = (response) => {
    console.log(response);
  }

  const onFinish = values => {
    if(values.password !== values.confirm_password){
      formRef.current.setFields([{'name' : 'confirm_password', 'errors' : ["Passowrd does not matched with confirm password!"]}]);
      return;
    }
    formRef.current.setFields([]);

    dispatch(showAuthLoader());
    dispatch(userSignUp(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <Card className="gx-card" title="Registration">
          <Steps current={0}>
            <FormStep title="Registration" description="Registration"/>
            <FormStep title="Email Verify" description="Email Verify"/>
            <FormStep title="Select PMS" description="Select PMS"/>
            <FormStep title="PMS Detail" description="Enter detail"/>
          </Steps>
        </Card>
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signUp"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png"/>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form 
              ref={formRef}
              initialValues={{remember: true}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              {/* <FormItem rules={[{required: true, message: 'Please input your username!'}]} name="Username">
                <Input placeholder="Username"/>
              </FormItem> */}

              <FormItem name="email" rules={[{
                required: true, type: 'email', message: 'The input is not valid E-mail!',
              }]}>
                <Input placeholder="Email"/>
              </FormItem>
              <FormItem name="password"
                        rules={[{required: true, message: 'Please input your Password!'}]}>
                <Input type="password" placeholder="Password"/>
              </FormItem>
              <FormItem name="confirm_password" rules={[{required: true, message: 'Please input your confirm password!'}]}>
                <Input type="password" placeholder="Confirm Password"/>
              </FormItem>

              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signUp"/>
                </Button>
                <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                id="app.userAuth.signIn"/></Link>
              </FormItem>
              <div className="gx-flex-row gx-justify-content-between">
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                  <GoogleLogin
                      clientId="207773640918-3moo7p0soh37ql0dvt6i1fii8mlspkim.apps.googleusercontent.com"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={'single_host_origin'}
                    />
                    {/* <GoogleOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGoogleSignIn());
                    }}/> */}
                  </li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                </ul>
              </div>
            </Form>
          </div>
          {loader &&
          <div className="gx-loader-view">
            <CircularProgress/>
          </div>
          }
          {showMessage &&
          message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};


export default SignUp;
