import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  googleAuthProvider
} from "../../firebase/firebase";


import {
  auth
} from "../../services/auth";

import {
  SIGNIN_GOOGLE_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  RESEND_EMAIL,
  SELECTED_PMS

} from "constants/ActionTypes";

import {
  showAuthMessage, 
  userSignInSuccess, 
  userSignOutSuccess, 
  userSignUpSuccess, 
  showErrorMessages, 
  updateUserPMSSuccess
} from "../../appRedux/actions/Auth";

import {
  userGoogleSignInSuccess,
} from "../actions/Auth";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth.userSignUp(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const resendEmailRequest = async (string) =>
  await auth.resendEmail(string)
    .then(authUser => authUser)
    .catch(error => error);
  
const PMSRequest = async (string) =>
    await auth.updateProfile(string)
      .then(authUser => authUser)
      .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await  auth.logIn(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async () =>
  await  auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGoogleRequest = async () =>
  await  auth.signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({payload}) {
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, payload);
    if (signUpUser.data.message) {
      yield put(showAuthMessage(signUpUser.data.message));
    } else if(signUpUser.data.errors && Object.keys(signUpUser.data.errors).length > 0){
      yield put(showErrorMessages(signUpUser.data.errors));
    }else {
      yield put(userSignUpSuccess((signUpUser.data.result) ? signUpUser.data.result.validateString : ''));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* resendEmailToUser({payload}) {
  try {
    const resendEmailData = yield call(resendEmailRequest, payload);
    if (resendEmailData.data.message) {
      yield put(showAuthMessage(resendEmailData.data.message));
    } else if(resendEmailData.data.errors && Object.keys(resendEmailData.data.errors).length > 0){
      yield put(showErrorMessages(resendEmailData.data.errors));
    }else {
      yield put(userSignUpSuccess((resendEmailData.data.result) ? resendEmailData.data.result.validateString : ''));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* updatePMSInfo({payload}) {
  try {
    const pmsInfo = yield call(PMSRequest, payload);
    if (pmsInfo.data.message) {
      yield put(showAuthMessage(pmsInfo.data.message));
      yield put(updateUserPMSSuccess((pmsInfo.data.result) ? pmsInfo.data.result.string : ''));
    } else if(pmsInfo.data.errors && Object.keys(pmsInfo.data.errors).length > 0){
      yield put(showErrorMessages(pmsInfo.data.errors));
    }else {
      yield put(updateUserPMSSuccess((pmsInfo.data.result) ? pmsInfo.data.result.string : ''));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithEmailPassword({payload}) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);
    localStorage.setItem('user_id', (signInUser.data.result) ? signInUser.data.result.user_id : '');
    localStorage.setItem('token', (signInUser.data.token) ? signInUser.data.token : '');
    if (signInUser.data.message) {
      yield put(showAuthMessage(signInUser.data.message));
      yield put(userSignInSuccess(localStorage.getItem('token')));
    } else {
      yield put(userSignInSuccess(localStorage.getItem('token')));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}




export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* sendEmail() {
  yield takeEvery(RESEND_EMAIL, resendEmailToUser);
}

export function* updateUserPMS() {
  yield takeEvery(SELECTED_PMS, updatePMSInfo);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(sendEmail),
    fork(signInWithGoogle),
    fork(signOutUser),
    fork(updateUserPMS),
  ]);
}
