
import axios from "axios";
let baseURL = "https://quibble.devtechnosys.info:7080";

const service = axios.create({ withCredentials: false, baseURL });
export const auth = {
  isLoggedIn: async () => {
    return await service.get("/api/user/is-logged-in");
  },
  userSignUp: async (data) => {
    return await service.post("/api/signup", data, {headers: {'Content-Type': 'application/json'}});
  },
  resendEmail: async (data) => {
    return await service.post("/api/resend_email", data, {headers: {'Content-Type': 'application/json'}});
  },
  updateProfile: async (data) => {
    return await service.post("/api/update_profile", data, {headers: {'Content-Type': 'application/json'}});
  },
  logIn: async (data) => {
    return await service.post("/api/login", data, {headers: {'Content-Type': 'application/json'}});
  },
  
//   logOut: async () => {
//     return await service.get("/logout");
//   },
};