import React, {useEffect} from "react";
import {Button, Form, Select, Card, Steps, Input} from "antd";
import {Link, useHistory} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  updateUserPMS
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";

const FormItem = Form.Item;
const Option = Select.Option;
const FormStep  = Steps.Step;

const SelectPms = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);
  const queryString = require('query-string');
  const parsed = queryString.parse(props.location.search);

  function handleChange(value) {
  }

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    
    /** Check user loggedin or not  */
    let token = localStorage.getItem('token');
    if (token && authUser) {
      history.push('/main/dashboard/listing');
    }

    if (!token && authUser !== null) {
      history.push('/pms_detail?string='+authUser+'&&status=success&&message=pms_update');
    }
  },[showMessage, authUser]);

  useEffect(() => {
    if(parsed.status == 'success'){
      message.error('Your email has been verified successfully.')
    }
  },[parsed.status]);


  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(updateUserPMS(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <Card className="gx-card" title="Registration">
          <Steps current={2}>
            <FormStep title="Registration" description="Registration"/>
            <FormStep title="Email Verify" description="Email Verify"/>
            <FormStep title="Select PMS" description="Select PMS"/>
            <FormStep title="PMS Detail" description="Enter detail"/>
          </Steps>
        </Card>
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.selectPms"/></h1>
              <p><IntlMessages id="app.userAuth.bySelectPms"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png"/>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{remember: true, pms : 'MyVR', string : parsed.string}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <FormItem name="pms" rules={[{
                required: true,  message: 'Please select PMS',
              }]}>
                <Select className="gx-mr-3 gx-mb-3" style={{width: 220}} onChange={handleChange}>
                  <Option value="MyVR">MyVR</Option>
                  <Option value="Streamline" disabled>Streamline</Option>
                  <Option value="OwnerRez" disabled>OwnerRez</Option>
                  <Option value="Guesty" disabled>Guesty</Option>
                </Select>
              </FormItem>
              <FormItem name="string"
                        rules={[{required: true}]}>
                <Input type="hidden" placeholder="string"/>
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.submit"/>
                </Button>
              </FormItem>
            </Form>
          </div>
          {loader &&
          <div className="gx-loader-view">
            <CircularProgress/>
          </div>
          }
          {showMessage &&
          message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};


export default SelectPms;
